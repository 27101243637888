@use 'styles/variables';

@import-normalize;

html {
  background-color: variables.$black;
  color: variables.$font-color;
  font-family: variables.$font-family;
  font-size: variables.$font-size;
  line-height: variables.$line-height;
}

html,
body {
  margin: 0;
}

* {
  box-sizing: border-box;
}
